<template>
  <b-container fluid class="component-reports-balance-productive-assets-multi-series-pie-chart">
    <pie-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'result-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </pie-chart>
  </b-container>
</template>

<script>
import PieChart from '@/components/chartjs/DoughnutChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import chartPalettes from '@/mixins/defaultChartColorPalettes'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'ProductiveAssetsMultiSeriesPieChart',
  components: { PieChart },
  mixins: [chartOptions, chartPalettes],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.productive_assets.table.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      const aProductiveLabels = []
      const aTmpProductiveAssets = []
      const aTmpNonProductiveAssets = []
      // const aNonProductiveAsset = []
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'

      /* Modify the Y-Axis */
      this.chartOptions.scales.yAxes[0].display = false
      this.chartOptions.scales.xAxes[0].display = false
      this.chartOptions.legend.display = true

      /* Make half circle */
      this.chartOptions.rotation = -Math.PI
      this.chartOptions.circumference = Math.PI

      /* Tooltip */
      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, chartData) {
        const fAmount = chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        let iLabelIndex = tooltipItem.index
        if (tooltipItem.datasetIndex === 1) {
          iLabelIndex += chartData.datasets[0].data.length
        }
        const sTitle = chartData.labels[iLabelIndex]

        return sTitle + ': ' + formatNumberToFull(fAmount) + ' ' + currencySymbol
      }

      /* Calculate datasets */
      const oDatasetOverall = {
        data: [this.apiData.assets.productive.closing_balance, this.apiData.assets.non_productive.closing_balance],
        backgroundColor: ['#FEC600', '#f95d6a']
      }

      /**
       * Productive dataset build
       */
      const oDatasetAssets = { data: [], backgroundColor: [] }
      for (const idx in this.apiData.accounts.productive) {
        if (this.apiData.accounts.productive[idx].balances.closing_balance !== 0) {
          aTmpProductiveAssets.push({ title: this.apiData.accounts.productive[idx].label, amount: this.apiData.accounts.productive[idx].balances.closing_balance })
        }
      }
      // Sort according to amount for better charts
      aTmpProductiveAssets.sort(function cmp (a, b) {
        return b.amount - a.amount
      })

      // Add to dataset
      for (const idx in aTmpProductiveAssets) {
        aProductiveLabels.push(aTmpProductiveAssets[idx].title)
        oDatasetAssets.data.push(aTmpProductiveAssets[idx].amount)
        oDatasetAssets.backgroundColor.push('#FFCE1F')
      }

      /**
       * Non-productive dataset build
       */
      // Non productive assets
      for (const idx in this.apiData.accounts.non_productive) {
        if (this.apiData.accounts.non_productive[idx].balances.closing_balance !== 0) {
          aTmpNonProductiveAssets.push({ title: this.apiData.accounts.non_productive[idx].label, amount: this.apiData.accounts.non_productive[idx].balances.closing_balance })
        }
      }
      // Sort according to amount for better charts
      aTmpNonProductiveAssets.sort(function cmp (a, b) {
        return b.amount - a.amount
      })
      // Add to dataset
      for (const idx in aTmpNonProductiveAssets) {
        aProductiveLabels.push(aTmpNonProductiveAssets[idx].title)
        oDatasetAssets.data.push(aTmpNonProductiveAssets[idx].amount)
        oDatasetAssets.backgroundColor.push('#fa7580')
      }

      aProductiveLabels.push(this.$t('reports.balance.productive_assets.table.productive'))
      aProductiveLabels.push(this.$t('reports.balance.productive_assets.table.non_productive'))

      /* Prepare data */
      this.chartData = {
        labels: aProductiveLabels,
        datasets: [oDatasetAssets, oDatasetOverall]
      }
    }
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
