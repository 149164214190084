<template>
  <div class="component-productive-assets-toggle-columns">
      <b-dropdown right
                  :text="$t('common.toggle_columns')"
                  variant='outline-secondary'
                  class="float-right text-regular mr-3 toggleColumnWrapper"
                  size="sm"
      >
        <b-dropdown-item v-if="options.asset_group" @click="toggleColumns('asset_group')" v-bind:class="{ active: optionValues.asset_group }">{{$t('reports.balance.productive_assets.table.th_group')}}</b-dropdown-item>
        <b-dropdown-item v-if="options.type" @click="toggleColumns('opening_balance')" v-bind:class="{ active: optionValues.opening_balance }">{{$t('reports.balance._common.opening_balance')}}</b-dropdown-item>
        <b-dropdown-item v-if="options.type" @click="toggleColumns('period_change')" v-bind:class="{ active: optionValues.period_change }">{{$t('reports.balance._common.period_change')}}</b-dropdown-item>
        <b-dropdown-item v-if="options.type" @click="toggleColumns('type')" v-bind:class="{ active: optionValues.type }">{{$t('common.type')}}</b-dropdown-item>
      </b-dropdown>

  </div>
</template>

<script>
export default {
  name: 'TableColumnToggler',
  props: {
    options: {
      type: Object,
      default: () => {
        return {}
      }
    },
    optionValues: {
      type: Object,
      default: function () {
        return {
          asset_group: true,
          type: true
        }
      }
    }
  },
  data () {
    return {
      translationPath: 'common.table-column-toggler.'
    }
  },
  methods: {
    toggleColumns (columnToDisplay) {
      this.$emit('column-to-toggle', columnToDisplay)
    }
  }
}
</script>

<style lang="scss">
.component-productive-assets-toggle-columns {
  li a { color: black; }
  li.active a:after {
    content: " ✓";
  }
  li.active a { font-weight: 900; }
}
</style>
