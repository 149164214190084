<template>
  <div class="component-reports-balance-productive-assets">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title') }}
                <b-badge v-if="bIsFilterApplied" class="h1badge ml-2" variant="warning">{{$t('reports._common.badge_filter_active')}} <b-link href="#" class="text-black" @click.stop="clearFilter">x</b-link></b-badge>
              </h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                default-period-type="rolling-3-months"
                :show-resolution="true"
                :default-resolution="resolution"
                :show-period-option-full-period="true"
                :allowed-resolutions="['month', 'year']"
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <report-submenu-component :active="activeTab" @tab-switched="onTabSwitched" />
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper" :class="{'d-none': activeTab === 'filter'}">
        <div class="pb-5" style="width:100%;height:500px;">

          <productive-assets-total-half-pie-chart v-if="activeTab === 'summary'"
              :currency="currency"
              :currencySymbol="currencySymbol"
              :apiData="apiData"
              :isLoaded="!busyState"
              :resolution="resolution"
          />

          <productive-assets-total-area-percentage-chart v-if="activeTab === 'period' && chartToDisplay === 'period-percentage-area-chart'"
              :currency="currency"
              :currencySymbol="currencySymbol"
              :apiData="apiData"
              :isLoaded="!busyState"
              :resolution="resolution"
            />

          <productive-assets-area-chart-period v-if="activeTab === 'period' && chartToDisplay === 'period-area-chart'"
               :currency="currency"
               :currencySymbol="currencySymbol"
               :apiData="apiData"
               :isLoaded="!busyState"
               :resolution="resolution"
          />

          <productive-assets-bar-chart-period v-if="activeTab === 'period' && chartToDisplay === 'period-bar-chart'"
               :currency="currency"
               :currencySymbol="currencySymbol"
               :apiData="apiData"
               :isLoaded="!busyState"
               :resolution="resolution"
          />

          <productive-assets-multi-series-pie-chart v-if="activeTab === 'list'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
          />

        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 transaction-view-wrapper">
      <!-- Table components here -->
      <productive-assets-total-table v-if="activeTab === 'summary'"
        :is-loaded="!busyState"
        :api-data="apiData"
        :start-date="startDate"
        :end-date="endDate"
        :resolution="resolution"
        :active-chart-option="chartToDisplay"
        @chart-to-display="(data) => chartToDisplay = data.chart"
      />
      <productive-assets-table-period v-if="activeTab === 'period'"
        :is-loaded="!busyState"
        :api-data="apiData"
        :start-date="startDate"
        :end-date="endDate"
        :resolution="resolution"
        :active-chart-option="chartToDisplay"
        @chart-to-display="(data) => chartToDisplay = data.chart"
      />
      <productive-assets-list-of-assets-table v-if="activeTab === 'list'"
        :is-loaded="!busyState"
        :api-data="apiData"
        :start-date="startDate"
        :end-date="endDate"
        :resolution="resolution"
        :active-chart-option="chartToDisplay"
        @chart-to-display="(data) => chartToDisplay = data.chart"
      />

      <report-filter
        v-if="activeTab === 'filter'"
        ref="ReportFilter"
        :filter-data="reportFilterData"
        :show-toast-on-filter-apply="false"
        :disabled="['income', 'expense', 'liability']"
        @filter-applied="onFilterApplied"
        @filter-data-updated="mergeFilterPartials"
        @current-filter-deleted="onFilterDeleted"
      />
    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import PeriodSelector from '@/components/common/PeriodSelector'
import axios from 'axios'
import ProductiveAssetsTotalTable from './tables/ProductiveAssetsTotalTable'
import ProductiveAssetsTablePeriod from './tables/ProductiveAssetsTablePeriod'
import ProductiveAssetsTotalHalfPieChart from './charts/ProductiveAssetsTotalHalfPieChart'
import ProductiveAssetsTotalAreaPercentageChart from './charts/ProductiveAssetsAreaPercentageChart'
import ProductiveAssetsBarChartPeriod from './charts/ProductiveAssetsBarChartPeriod'
import ProductiveAssetsAreaChartPeriod from './charts/ProductiveAssetsAreaChartPeriod'
import ProductiveAssetsListOfAssetsTable from './tables/ProductiveAssetsListOfAssetsTable'
import ProductiveAssetsMultiSeriesPieChart from './charts/ProductiveAssetsMultiSeriesPieChart'
import ReportSubmenuComponent from '@/views/reports/_inc/ReportSubmenuComponent'
import ReportFilter from '@/components/common/ReportFilter.vue'

export default {
  name: 'ProductiveAssetsBalanceReport',
  mixins: [titleMixins],
  components: {
    ProductiveAssetsBarChartPeriod,
    ProductiveAssetsTotalAreaPercentageChart,
    ProductiveAssetsTotalHalfPieChart,
    ProductiveAssetsAreaChartPeriod,
    ProductiveAssetsTablePeriod,
    PeriodSelector,
    ReportSubmenuComponent,
    ProductiveAssetsTotalTable,
    ProductiveAssetsListOfAssetsTable,
    ProductiveAssetsMultiSeriesPieChart,
    ReportFilter
  },
  data () {
    return {
      validRouteParams: ['summary', 'period', 'list', 'filter'],
      activeTab: 'summary',
      apiData: null,
      bHasAPIDataBeenFetchedOnce: false,
      translationPath: 'reports.balance.productive_assets.',
      busyState: true,
      startDate: null,
      endDate: null,
      resolution: 'month',
      chartToDisplay: 'total-pie-chart',
      reportFilterData: null,
      bIsFilterApplied: false
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      let aBreadcrumb = []

      aBreadcrumb = [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports/balance' },
        { text: this.$t('reports._common.balance_reports'), to: '/reports/balance/' },
        { text: '', active: true }
      ]

      return aBreadcrumb
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    currency () {
      return this.currentCOA.currency_iso ? this.currentCOA.currency_iso : ''
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    /**
     * Handle emitted data from period_selector
     */
    onPeriodChange (data) {
      this.startDate = data.sDateStart
      this.endDate = data.sDateEnd
      this.resolution = data.resolution

      this.loadData()
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) !== -1) {
        this.$router.push({ params: { tab: newtab } })
      } else if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) === -1) {
        this.$router.push({ params: { tab: 'summary' } })
      }
      switch (newtab) {
        case 'summary' :
          this.chartToDisplay = 'total-pie-chart'
          break
        case 'period' :
          if (this.chartToDisplay === 'total-pie-chart') {
            this.chartToDisplay = 'period-bar-chart'
          }
          break
      }
    },
    async loadData () {
      return new Promise((resolve, reject) => {
        this.busyState = true

        let sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/balance/productive-assets?start_date=${this.startDate}&end_date=${this.endDate}&resolution=${this.resolution}`

        // Filter accounts
        const aIncludeAccounts = []
        if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectAsset')) {
          this.reportFilterData.oFormSelectAsset.map(a => { aIncludeAccounts.push(a) })
        }
        if (aIncludeAccounts.length) {
          sApiEndpoint += '&filter_accounts_assets=' + aIncludeAccounts.join(',')
        }

        // Filter persons
        if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectPerson')) {
          sApiEndpoint += '&filter_persons=' + this.reportFilterData.oFormSelectPerson.join(',')
        }

        axios.get(sApiEndpoint)
          .then(response => {
            this.apiData = response.data.data
            this.busyState = false
            this.bHasAPIDataBeenFetchedOnce = true
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    onFilterApplied (eventData) {
      this.bIsFilterApplied = true
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    },
    onFilterDeleted () {
      this.bIsFilterApplied = false
      this.loadData()
    },
    mergeFilterPartials (partialData) {
      if (!this.reportFilterData) {
        this.reportFilterData = null
      }
      this.reportFilterData = Object.assign({}, this.reportFilterData, partialData)
    },
    clearFilter () {
      this.bIsFilterApplied = false
      if (this.$refs.ReportFilter) {
        this.$refs.ReportFilter.reset()
      } else {
        this.reportFilterData.oFormSelectIncome = []
        this.reportFilterData.oFormSelectExpense = []
        this.reportFilterData.oFormSelectAsset = []
        this.reportFilterData.oFormSelectLiability = []
        this.reportFilterData.oFormSelectPerson = []
        this.reportFilterData.iFormSelectFilter = 0
        this.reportFilterData.oFilterLoaded = null
        this.reportFilterData.oFormFilterTitle = ''
        this.reportFilterData.oFormFilterDescription = ''
      }
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    }
  },
  mounted () {
    if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
      this.activeTab = this.$route.params.tab
      switch (this.$route.params.tab) {
        case 'summary':
          this.chartToDisplay = 'total-pie-chart'
          break
        case 'period':
          this.chartToDisplay = 'period-bar-chart'
          break
      }
    } else {
      this.$router.push({ params: { tab: 'summary' } })
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-reports-balance-productive-assets {
  width: 100%;
}
</style>
