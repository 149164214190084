<template>
  <div class="component-productive-assets-period-table">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title_period_table', { start_date: startDate, end_date: endDate }) }}</h2>
                </b-col>
                <b-col cols="4" class="text-right">
                  <!-- Toggle columns component here -->

                  <!-- Toggle charts -->
                  <b-dropdown right
                              :text="$t('common.toggle_chart')"
                              variant='outline-secondary'
                              class="float-right mr-3 toggle-dropdown-item-wrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('period-bar-chart')" v-bind:class="{ active: (showChart === 'period-bar-chart') }">{{$t( translationPath + 'charts.period-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-area-chart')" v-bind:class="{ active: (showChart === 'period-area-chart') }">{{$t( translationPath + 'charts.period-area-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-percentage-area-chart')" v-bind:class="{ active: (showChart === 'period-percentage-area-chart') }">{{$t( translationPath + 'charts.period-percentage-area-chart')}}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <p>{{ $t(translationPath + 'description_values') }}</p>
            </div>

            <b-table
              class="spirecta-simple-table cashflow-quadrant-table mb-3"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :tbodyTrClass="trClass"
            >
              <template v-slot:table-busy><loader/></template>

              <!-- title slot-->
              <template v-slot:cell(title)="row">
                {{ $t(row.value) }}
              </template>

              <!-- Period slots HEAD -->
              <template v-slot:[gomyhead(sPeriod)]="row" v-for="sPeriod in this.apiData ? this.apiData.meta.periods : []">
                <template v-if="resolution === 'month' && Object.prototype.hasOwnProperty.call(row.field, 'meta')">
                  {{ $t('common.months.' + months[row.field.meta.month]) }} <span v-if="!row.field.meta.month" :key="sPeriod">{{ row.field.meta.year }}</span>
                </template>
                <template v-else>
                  {{ row.field.label }}
                </template>
              </template>

              <!-- Period slots -->
              <template v-slot:[gomycell(sPeriod)]="row" v-for="sPeriod in this.apiData ? this.apiData.meta.periods : []">
                <template v-if="row.item.row_type === 'empty'">
                  <template v-if="resolution === 'month'">
                    {{ $t('common.months.' + months[row.field.meta.month]) }}
                  </template>
                  <template  v-else>
                    {{ row.field.label }}
                  </template>
                </template>
                <template v-else>
                  {{ amountFormatter(row.value) }}
                </template>
              </template>

              <!-- END CUSTOM SLOTS -->
            </b-table>

          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'ProductiveAssetsTablePeriod',
  components: { Loader },
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    resolution: { type: String },
    activeChartOption: { type: String, default: 'period-bar-chart' },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showChart: 'bar-chart',
      translationPath: 'reports.balance.productive_assets.table.',
      tableData: [],
      tableColumns: [],
      months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'period', chart: showChart })
    },
    buildTableDataRows () {
      const data = {
        productive_amounts: { title: this.translationPath + 'productive', average: 0, median: 0, min: 0, max: 0, row_type: 'data' },
        non_productive_amounts: { title: this.translationPath + 'non_productive', average: 0, median: 0, min: 0, max: 0, row_type: 'data' },
        uncategorized_amounts: { title: this.translationPath + 'uncategorized', average: 0, median: 0, min: 0, max: 0, row_type: 'data' },
        productive_percentages: { title: this.translationPath + 'productive', average: 0, median: 0, min: 0, max: 0, row_type: 'data' },
        non_productive_percentages: { title: this.translationPath + 'non_productive', average: 0, median: 0, min: 0, max: 0, row_type: 'data' },
        uncategorized_percentages: { title: this.translationPath + 'uncategorized', average: 0, median: 0, min: 0, max: 0, row_type: 'data' }
      }
      const sumRow = { title: 'common.total', row_type: 'total' }
      const emptyRow = { title: this.$t(this.translationPath + 'th_types'), row_type: 'empty' }
      const sumRowPercentages = { title: 'common.total', row_type: 'total' }

      if (this.apiData) {
        for (const sProductiveType in this.apiData.assets) {
          // data[quadrant].total = this.apiData.quadrants[quadrant].total_amounts.sum

          for (const period in this.apiData.assets[sProductiveType].period_balances) {
            // Amounts
            data[sProductiveType + '_amounts'][period] = this.apiData.assets[sProductiveType].period_balances[period]
            sumRow[period] = this.apiData.totals.period_balances[period]

            // Percentages
            data[sProductiveType + '_percentages'][period] = this.apiData.assets[sProductiveType].period_percentages[period] + ' %'
            sumRowPercentages[period] = '100.00 %'
          }
        }
      }

      const rows = [data.productive_amounts, data.non_productive_amounts]
      if (this.apiData && this.apiData.meta.is_uncategorized_non_zero) {
        rows.push(data.uncategorized_amounts)
      }
      rows.push(sumRow)

      // Empty row
      rows.push(emptyRow)

      // Percentage rows
      rows.push(data.productive_percentages)
      rows.push(data.non_productive_percentages)
      if (this.apiData && this.apiData.meta.is_uncategorized_non_zero) {
        rows.push(data.uncategorized_percentages)
      }
      rows.push(sumRowPercentages)

      return rows
    },
    buildTableColumns () {
      const cols = [{ key: 'title', label: this.$t(this.translationPath + 'th_types') }]

      if (this.apiData) {
        this.apiData.meta.periods.map(period => {
          const col = { key: String(period), label: period, class: 'text-right td-period' }

          if (this.resolution === 'month') {
            const ym = period.split('-')
            col.meta = { year: Number(ym[0]), month: Number(ym[1] - 1) }
          }
          cols.push(col)
        })
      }
      return cols
    },
    gomycell (key) {
      return `cell(${key})`
    },
    gomyhead (key) {
      return `head(${key})`
    },
    amountFormatter (value) {
      return this.currentCOA.locale ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, false, 0) : Math.ceil(value)
    },
    trClass (item) {
      if (!item) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(item, 'row_type')) {
        return 'tr-' + item.row_type.replace(/_/, '-')
      }

      return ''
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    },
    activeChartOption (v) {
      this.showChart = v
    }
  }
}
</script>

<style lang="scss">
.component-productive-assets-period-table{
  .tr-empty td{
    background: white;
    padding-top: 30px;
    font-weight: bold;
  }
}
</style>
